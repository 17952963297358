import Vue from 'vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueI18n from 'vue-i18n'
import language from './assets/data/language.json'

import Print from 'vue-print-nb';

Vue.use(Print); //注册

import './assets/lib/common.css'
import './assets/lib/el-common.css'
import './assets/lib/flexiable'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import frLocale from 'element-ui/lib/locale/lang/fr'
import {request, requestFile, getRequest} from './utils/http';

import html2canvas from 'html2canvas'
import printJS from 'print-js'

Vue.prototype.postRequest = request
Vue.prototype.postFile = requestFile
Vue.prototype.getRequest = getRequest
Vue.prototype.logoutAndClear = function (data) {
    request('Index/log_out', {uid: sessionStorage.getItem('id'), ...data}, (res) => {
    })
    sessionStorage.clear()
}
export const TIMEOUT_DURATION = 1800000; // 定义超时时间
Vue.prototype._logoutIfTimeout = function (lastTime) {
    //const lastTime = sessionStorage.getItem('lastTime');
    if (lastTime != 'null') {
        //console.log('_logoutIfTimeout check time...' + Number(lastTime) + ' now:' + new Date().getTime())
        return (new Date().getTime() - Number(lastTime) > TIMEOUT_DURATION);
    } else {
        //console.log('_logoutIfTimeout return true...')
        return true;
    }
};
// 在全局定义 logoutIfTimeout
Vue.prototype.logoutIfTimeout = function (lastTime) {
    if (this._logoutIfTimeout()) {
        this.$message({
            type: 'warning',
            message: this.$t('message.a19')
        });

        this.logoutAndClear({ type: 0 });
        this.$router.push('/');
        return true; // 返回 true 表示超时
    } else {
        return false; // 返回 false 表示未超时
    }
};

Vue.prototype.html2canvas = html2canvas
Vue.prototype.printJS = printJS

Vue.config.productionTip = false
Vue.use(VueI18n) // 通过插件的形式挂载
Vue.use(ElementUI);

// 屏蔽错误信息
// Vue.config.errorHandler = (err) => {
//   // console.log(err);
// };
// // 屏蔽警告信息
// Vue.config.warnHandler = (message) => {
//   // console.log(message);
// };

const i18n = new VueI18n({
    // locale: 'mn', // 语言标识
    locale: 'fr', // 语言标识
    messages: {
        fr: Object.assign(language.fr, frLocale),
        en: Object.assign(language.en, enLocale),
    }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))


window.vue1 = new Vue({
    render: h => h(App),
    router,
    i18n,
    data() {
        return {
            status: 1, //设置一个全局变量，代表编辑或者新增步骤

            progress: 0,//设置一个全局变量，代表最后一个模块下载进度
            fileUrl: '',//设置一个全局变量，代表最后一个模块下载进度
            state: '', //设置一个全局变量，代表最后一个模块下载进度
        }
    }
}).$mount('#app')
